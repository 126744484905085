import { graphql, useStaticQuery } from 'gatsby'
import { useSelector } from 'react-redux'
import { RootState } from 'utils/store/store'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'
import { PrismicBodyDownloadBar, PrismicBodyDownloadBarSlice, PrismicBodyDownloadBarEdge } from './DownloadBarSliceTypes'

export const useDownloadBarSliceData = (): PrismicBodyDownloadBarSlice => {
    const { language } = useSelector((state: RootState) => state.i18n)
    const result = useStaticQuery(graphql`
      {
        allPrismicHomepage {
          edges {
            node {
              lang
              _previewable
              data {
                body {
                  ... on PrismicHomepageDataBodyDownloadBarSlice {
                    id
                    slice_type
                    primary {
                      download_bar_button_text {
                        richText
                      }
                      download_bar_subtitle {
                        richText
                      }
                      download_bar_title {
                        richText
                      }
                      download_link {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `)
   
    const { data } = useMergePrismicPreviewData(result)
  
    return data.allPrismicHomepage.edges
      .map((edge: PrismicBodyDownloadBarEdge) => edge.node)
        .filter((node: PrismicBodyDownloadBar) => node.lang === language)
          .map((node: PrismicBodyDownloadBar) => node.data?.body)[0][0]
  }
