import { RichText } from 'prismic-reactjs'
import { useDownloadBarSliceData } from './downloadBarSliceService'
import React from 'react'
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { colors, productColors } from 'shared/constants'
import Button from 'ui/button/Button'

const Section = styled.section(() => css`
  background-color: ${productColors.lightViolet};
  color: ${colors.darkBlue};
`)

const Wrapper = styled.div(() => {
  const { tablet, mobile } = useMediaQuery()
  
  return css`
    margin: 0 auto;
    max-width: 1440px;
    padding: ${tablet ? '2rem 1.5rem' : mobile ? '1.5rem 1rem' : '2rem 5.25rem'};
    display: flex;
    justify-content: space-around;
    align-items: center;
    
    h1 {
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  `
})

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 1.5rem;
  color: ${colors.darkBlue};
`

const DownloadBarSlice = () => {
  const data = useDownloadBarSliceData()
  
  return (
    <Section>
      <Wrapper>
        <Content>
          <RichText render={data?.primary?.download_bar_title?.richText} />
          <Subtitle>{RichText.asText(data?.primary?.download_bar_subtitle?.richText)}</Subtitle>
        </Content>
        <Button
          to={data?.primary?.download_link?.url || ''}
          filled
          minWidth={300}
        >
          {RichText.asText(data?.primary?.download_bar_button_text?.richText)}
        </Button>
      </Wrapper>
    </Section>
  )
}

export default DownloadBarSlice
